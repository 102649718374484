<template>
    <div class="admin-competition full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                    >
                        <v-row>
                            <v-col class="d-flex align-center justify-space-between" cols="12">
                                <h2>Edit Room</h2>

                                <div class="action-buttons">
                                    <v-btn
                                        class="ml-3"
                                        :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="primary"
                                        small
                                        @click="exportCSV"
                                    >
                                        Export CSV
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else>Close</template>
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ success: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="success"
                                        :disabled="!valid"
                                        :loading="saving"
                                        small
                                        @click="save"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="white" small>mdi-check</v-icon>
                                        </template>

                                        <template v-else>Save</template>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>

                    <v-container class="mb-6" style="max-width: 768px">
                        <v-row dense>
                            <v-col cols="12">
                                <p class="primary--text small--text">Room Title</p>
                                <v-text-field
                                    ref="email"
                                    v-model="room.title"
                                    type="text"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter title..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12">
                                <p class="primary--text small--text">Judge Seats</p>
                                <v-text-field
                                    ref="email"
                                    v-model="room.data.limit"
                                    type="text"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter seats..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense v-if="room.data && room.data.entries">
                            <v-col cols="12">
                                <div class="d-flex align-center mb-4 justify-space-between">
                                    <p class="primary--text small--text ma-0">Entries</p>

                                    <div class="action-buttons">
                                        <v-btn
                                            class="ml-3"
                                            :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                            :icon="$vuetify.breakpoint.xsOnly"
                                            color="primary"
                                            small
                                            @click="sort"
                                        >
                                            <v-icon
                                                color="white"
                                                small
                                                v-html="
                                                    sorting
                                                        ? 'mdi-sort-alphabetical-ascending'
                                                        : 'mdi-sort-alphabetical-descending'
                                                "
                                            ></v-icon>

                                            <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Sort</span>
                                        </v-btn>

                                        <v-btn
                                            class="light--text ml-3"
                                            :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                            :icon="$vuetify.breakpoint.xsOnly"
                                            color="dark"
                                            small
                                            @click="randomise"
                                        >
                                            <v-icon color="light" small>mdi-shuffle</v-icon>
                                            <span class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">Randomise</span>
                                        </v-btn>
                                    </div>
                                </div>

                                <sortable-list class="mb-6" v-model="room.data.entries" :key="cache">
                                    <template v-slot:item="{ item }">
                                        <div class="image-container flex-shrink-1 mr-6">
                                            <template v-if="hasImage(item)">
                                                <v-img
                                                    class="rounded-lg elevation-4 grey"
                                                    width="35"
                                                    height="35"
                                                    :src="$fluro.asset.imageUrl(item.data.image)"
                                                >
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height grey ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                                :width="1"
                                                                :size="14"
                                                                color="dark"
                                                                indeterminate
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                            </template>

                                            <template v-else>
                                                <v-row
                                                    class="rounded-lg elevation-4 fill-height full-width grey ma-0"
                                                    style="width: 35px; height: 35px"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-icon color="dark-grey">mdi-image-outline</v-icon>
                                                </v-row>
                                            </template>
                                        </div>

                                        <b class="small--text" style="width: 200px">{{ item.data.entryCode }}</b>
                                        <template v-if="item?.managedAuthor">
                                            <populate-item
                                                class="small--text"
                                                :id="item?.managedAuthor"
                                                type="persona"
                                                object-key="title"
                                            ></populate-item>
                                        </template>
                                        <template v-else>
                                            <span>{{ item?.data?.entrantName }}</span>
                                        </template>
                                    </template>
                                </sortable-list>

                                <v-btn color="primary" @click="addEntry()">Add entry</v-btn>
                            </v-col>

                            <content-list-dialog
                                v-model="room.data.entries"
                                ref="contentDialog"
                                title="Select Entries"
                                type="entry"
                                :headers="headers"
                                @confirm="confirmAdd"
                            ></content-list-dialog>
                        </v-row>
                        <v-row dense v-if="room.data && room.data.selectableJudges">
                            <v-col cols="12">
                                <div class="d-flex align-center mb-4 justify-space-between">
                                    <p class="primary--text small--text ma-0">Judges</p>
                                </div>
                            </v-col>

                            <sortable-list
                                class="mb-6"
                                style="width: 100%"
                                v-model="room.data.selectableJudges"
                                :key="cache"
                            >
                                <template v-slot:item="{ item }">
                                    <b class="small--text judge-item"> First name - {{ item.firstName }}</b>
                                    <b class="small--text judge-item"> Last name - {{ item.lastName }}</b>
                                </template>
                            </sortable-list>
                        </v-row>
                        <content-list-dialog
                            v-model="room.data.selectableJudges"
                            ref="contentDialogJudge"
                            title="Select Judges"
                            type="persona"
                            :headers="headersJudge"
                            @confirm="confirmAddJudges"
                        ></content-list-dialog>
                        <v-btn color="primary" @click="addJudge()">Add Judge</v-btn>
                    </v-container>
                </div>
            </template>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>
    </div>
</template>

<script>
// Services
import Room from "@/modules/app-module/room";
import Entry from "@/modules/app-module/entry";

import PopulateItem from "@/components/global/populate-item.vue";
import SortableList from "@/components/form-field/sortable-list.vue";
import ContentListDialog from "@/components/dialogs/content-list-dialog.vue";

// Packages
import _ from "lodash";

export default {
    name: "admin-room",

    meta: {
        title: "Room",
    },

    components: {
        PopulateItem,
        SortableList,
        ContentListDialog,
    },

    data() {
        return {
            error: false,
            scrolled: false,
            errorMessage: "",
            loading: false,
            saving: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            room: {},
            cache: 0,
            sorting: false,
            headers: [
                {
                    text: "Image",
                    value: "image",
                    parent: "data",
                    type: "image-cell",
                    sortable: false,
                },
                {
                    text: "Entry Code",
                    value: "entryCode",
                    parent: "data",
                    type: "text-cell",
                    sortable: false,
                },
                {
                    text: "Category",
                    value: "category",
                    parent: "data",
                    type: "text-cell",
                    sortable: false,
                },
                {
                    text: "Artist",
                    value: "managedAuthor",
                    type: "object-cell",
                    sortable: false,
                },
            ],
            headersJudge: [
                { text: "Name", value: "title", type: "text-cell" },
                { text: "Email Address", value: "username", type: "text-cell" },
            ],

            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        valid() {
            return true;
        },
    },

    methods: {
        hasImage(item) {
            let image = item?.data?.image;

            return image?._id || image;
        },
        image(entry) {
            return entry?.data?.image;
        },
        async init() {
            this.loading = true;

            try {
                this.room = await Room.get(this.$route.params.room).then(({ data }) => data);

                this.room.data.entries = this?.room?.data?.entries || [];

                console.log("ROOM", this.room);
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.loading = false;
        },
        addEntry() {
            this.$refs.contentDialog.open();
        },
        addJudge() {
            this.$refs.contentDialogJudge.open();
        },
        confirmAdd(selected) {
            this.$refs.contentDialog.close();
            this.room.data.entries = selected;
            this.cache++;
        },
        confirmAddJudges(selected) {
            this.$refs.contentDialogJudge.close();
            this.room.data.selectableJudges = selected;
            this.cache++;
        },
        close() {
            this.$router.push({ name: "admin.competition-rooms", params: { id: this.$route.params.id } });
        },
        sort() {
            this.sorting = !this.sorting;

            if (this?.room?.data?.entries?.length) {
                this.room.data.entries.sort((a, b) => {
                    let aEntryCode = a.data.entryCode;
                    let bEntryCode = b.data.entryCode;

                    if (this.sorting) {
                        return bEntryCode.localeCompare(aEntryCode);
                    }

                    return aEntryCode.localeCompare(bEntryCode);
                });
            }
        },
        randomise() {
            if (this?.room?.data?.entries?.length) {
                this.room.data.entries = this.shuffle(this.room.data.entries);
                this.cache++;
            }
        },
        shuffle(array) {
            let currentIndex = array.length,
                randomIndex;

            // While there remain elements to shuffle.
            while (currentIndex != 0) {
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }

            return array;
        },
        async save() {
            this.saving = true;

            try {
                this.room = await Room.update(this.room._id, this.room).then(({ data }) => data);
                console.log("UPDATED", this.room);
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.saving = false;
        },
        async exportCSV() {
            let ids = this.room.data.entries.map(({ _id }) => _id);

            let query = {
                _type: "article",
                definition: "entry",
                _id: {
                    $in: ids,
                },
            };

            let items = await Entry.query(query).then(({ data }) => data);

            items.sort((a, b) => {
                return ids.indexOf(a._id) - ids.indexOf(b._id);
            });

            let data = {
                title: this.room.title,
                items,
                headers: this.headers,
            };

            await this.$app.exportCSVItems(data);
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop > 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss">
.judge-item {
    width: 100%;
}
</style>
