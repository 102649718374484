<template>
    <span>{{ string }}</span>
</template>

<script>
export default {
    name: "populate-item",

    props: {
        id: {
            type: [Object, String],
        },
        type: {
            type: String,
        },
        objectKey: {
            type: String,
        },
    },

    data() {
        return {
            string: "",
        };
    },

    methods: {
        async init() {
            if (!this.id && !this.type && !this.objectKey) return;

            if (!this.id?._id) {
                let item = await this.$fluro.api.get(`/content/${this.type}/${this.id}`).then(({ data }) => data);

                this.string = item[this.objectKey];
            } else {
                this.string = this.id[this.objectKey];
            }
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>
