<template>
    <div class="sortable-list">
        <div class="rounded-lg group-item d-flex flex-column">
            <draggable class="full-width" :list="items" v-bind="dragOptions" handle=".handle">
                <div class="pa-2 d-flex align-center full-width justify-space-between" :class="{ 'border-bottom': index != items.length - 1 }" v-for="(item, index) in items" :key="item._id">
                    <div class="d-flex align-center full-width">
                        <v-btn icon x-small class="mr-2 handle">
                            <v-icon x-small>mdi-cursor-move</v-icon>
                        </v-btn>

                        <slot name="item" v-bind:item="item"></slot>
                    </div>

                    <v-btn icon x-small @click="removeItem(index)">
                        <v-icon x-small>mdi-close</v-icon>
                    </v-btn>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import Draggable from "vuedraggable-axis";

export default {
    name: "new-template",

    props: {
        value: {
            type: Array,
        },
    },

    components: {
        Draggable,
    },

    data() {
        return {
            items: this.value,
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 250,
                disabled: false,
                ghostClass: "dragging",
            };
        },
    },

    methods: {
        removeItem(index) {
            this.items.splice(index, 1);
        },
    },

    async mounted() {},
};
</script>

<style lang="scss"></style>
